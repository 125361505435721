@import "../../../assets/theme/_var.scss";

.staking_pool {
  .staking_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .switch {
      .form-check-input {
        @include theme() {
          background-color: theme-get("bgWhite33");
          background-position: 0.2rem;
          cursor: pointer;

          &:checked {
            background-position: 2rem;
          }
        }
      }

      label {
        @include theme() {
          color: theme-get("textclr");
        }
      }
    }

    .search_input {
      width: 100%;
      max-width: 35rem;
      position: relative;
      z-index: 0;

      @media (max-width: 499px) {
        max-width: 100%;
      }

      span {
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translate(0%, -50%);
        z-index: 1;
      }

      input {
        height: 3.8rem;
        width: 100%;
        padding: 0.6rem 1.4rem 0.7rem 1.4rem;
        border-radius: 0.8rem;
        border: none;
        // background: #0000001a;
        backdrop-filter: blur(28px);
        // color: $white !important;
        font-size: 1.6rem;
        padding-left: 4.3rem;
        font-weight: 500;

        &:focus {
          outline: none;
        }

        &::placeholder {
          opacity: 0.6;
          color: $white !important;

        }

        @include theme() {
          background-color: theme-get("bgWhite33");
          color: theme-get("textclr");
        }
      }
    }
  }

  .leftSideFilter {
    display: flex;
    align-items: center;

    @media (max-width: 479px) {
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .selectStaking {
      margin-left: 1rem;

      @media (max-width: 479px) {
        margin: 1rem 0 0;

        &:last-child {
          margin-left: 1rem;
        }
      }

      .common_select_inner {
        .react-select {
          &__control {
            border: none;
            background: #0000001a;
            height: 3.8rem;
            width: max-content;
            white-space: nowrap;

            .react-select__indicators {
              .react-select__indicator-separator {
                display: none;
              }

              .react-select__indicator {
                padding: 0 0.8rem;

                svg {
                  path {
                    fill: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .shimmer_row {
    margin: -1rem;

    >div {
      padding: 1rem;
    }
  }

  &_faq {
    h2 {
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 2.6rem;
      line-height: 1.6;
    }

    .accordion {
      .accordion-item {
        border: none;
        background: transparent;
        margin-bottom: 2rem;

        .accordion-header {
          background: transparent;
          margin: 0;

          .accordion-button {
            border: none;
            font-family: $fontSecondry;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 1.5;
            justify-content: space-between;
            border-radius: 1.6rem 1.6rem 0 0;
            box-shadow: none;
            padding: 1.6rem 2.2rem;
            padding-bottom: 0.6rem;

            span {
              font-family: $fontSecondry;
            }

            @include theme() {
              background: theme-get("secondryBg");
              color: theme-get("textclr");
            }

            svg {
              transition: ease-in-out all 0.3s;
              transform: rotate(180deg);
            }

            &::after {
              content: unset;
            }

            &:focus {
              box-shadow: none;
              outline: none;
            }

            &.collapsed {
              background: transparent;
              box-shadow: none;
              padding-top: 0.6rem;

              svg {
                transform: rotate(0deg);
              }
            }
          }
        }

        .accordion-collapse {
          .accordion-body {
            border-radius: 0 0 1.6rem 1.6rem;
            padding: 0.6rem 5rem 1.6rem 2.2rem;

            @include theme() {
              background: theme-get("secondryBg");
            }

            p {
              color: #a1a1a1;
              font-size: 1.4rem;
              line-height: 1.6;
              font-weight: 500;
              font-family: $fontSecondry;
            }
          }

          &.show {
            .accordion-body {
              box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
  }

  .tableStaking {
    border-spacing: 0;

    thead {
      tr {
        th {
          padding-top: 1rem;
          padding-bottom: 1rem;

          &:last-child {
            text-align: center;
            position: unset;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 1.4rem;
          line-height: 1.6;
          font-weight: 500;
          width: calc(100% / 6);
          white-space: nowrap;

          &:last-child {
            text-align: center;
            position: unset;
            min-width: 16.9rem;
          }

          &:nth-child(2),
          &:nth-child(3) {
            min-width: 14.7rem;
          }

          .infoToken {
            display: flex;
            align-items: center;

            img {
              width: 1.8rem;
              height: auto;
            }

            &_text {
              margin-left: 1.6rem;

              h5 {
                font-size: 1.8rem;
                line-height: 1.6;
                font-weight: 400;
              }

              p {
                font-size: 1.6rem;
                line-height: 1.6;
                font-weight: 400;

                @include theme() {
                  color: theme-get("textTd");
                }
              }
            }
          }

          .earningText {
            h6 {
              font-size: 1.8rem;
              line-height: 1.6;
              font-weight: 400;
            }

            p {
              color: #cbd5e1;
              font-size: 1.6rem;
              line-height: 1.6;
              font-weight: 400;
            }
          }

          .secformData {
            label {
              color: #a1a1a1;
              font-size: 1.4rem;
              line-height: 1.6;
              font-weight: 500;
            }

            p {
              font-size: 1.4rem;
              line-height: 1.6;
              font-weight: 500;
            }
          }

          .earningTable {
            display: flex;
            align-items: center;

            &_left {
              display: flex;
              align-items: center;
              margin-right: 2rem;

              .btnEnable {
                margin-left: 2rem;
              }
            }

            &_right {
              display: flex;
              align-items: center;

              .secformData {
                text-align: left;
              }

              .btnEnable {
                margin-left: 2rem;
              }
            }
          }

          .arrowBtn {
            padding: 0;
            width: max-content;
            min-width: 3rem;
            transition: ease-in-out all 0.3s;
          }

          .aprgreen {
            color: #00f260;
          }

          .textGradient {
            background-clip: text !important;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;

            @include theme() {
              background: theme-get("textgradient");
            }
          }
        }

        &.tableRowactive {
          transition: ease-in-out all 0.3s;

          td {
            background: #0057ff33;

            &:first-child {
              border-radius: 1rem 0 0 0;
            }

            &:last-child {
              border-radius: 0 1rem 0 0;
              background: #0057ff33;
            }

            .arrowBtn {
              transform: rotate(180deg);
            }
          }

          &.moreDetails {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 97%;
              height: 0.1rem;
              z-index: 5;

              @include theme() {
                background-color: theme-get("bgWhite33");
              }
            }

            td {
              &:first-child {
                border-radius: 0 0 0 1rem;
              }

              &:last-child {
                border-radius: 0 0 1rem 0;
              }
            }
          }
        }

        &.spacingTr {
          padding: 0.7rem 0;
          display: inline-block;

          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  .nav {
    width: 100%;
    border-radius: 0.8rem;
    border: 0.1rem solid rgba($white, 0.1);
    padding: 0.4rem;
    margin-bottom: 2.5rem;

    @include theme() {
      background: theme-get("cardfieldBg");
    }

    .nav-item {
      flex: 0 0 50%;

      .nav-link {
        width: 100%;
        height: 4.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $fontSecondry;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2.6rem;

        @include theme() {
          color: theme-get("textclr");
        }

        @media (max-width: 991px) {
          font-size: 1.6rem;
          line-height: 2rem;
          height: 4rem;
        }

        &.active {
          border-radius: 1rem;
          background: $gradient;
          position: relative;
          color: $white;
        }
      }
    }
  }

  .cardSection {
    // margin-top: 7rem;

    @media (max-width: 1399px) {
      margin-top: 5rem;
    }

    @media (max-width: 1199px) {
      margin-top: 4.5rem;
    }

    @media (max-width: 991px) {
      margin-top: 3.5rem;
    }

    h2 {
      font-size: 3.2rem;
      font-weight: 700;
      position: relative;
      line-height: 1.6;

      @media (max-width: 1679px) {
        font-size: 3rem;
      }

      @media (max-width: 1399px) {
        font-size: 2.8rem;
      }

      @media (max-width: 1199px) {
        font-size: 2.4rem;
      }

      @media (max-width: 991px) {
        font-size: 2rem;
      }
    }

    &_inner {
      margin-top: 3.3rem;

      @media (max-width: 1399px) {
        margin-top: 2.5rem;
      }

      @media (max-width: 1199px) {
        margin-top: 2rem;
      }

      @media (max-width: 991px) {
        margin-top: 1.5rem;
      }

      .CardField {
        position: relative;
        border-radius: 1.6rem;
        box-shadow: 0px 10px 60px 0px #0000001a;
        padding: 2rem;
        margin-bottom: 2rem;

        @include theme() {
          background: theme-get("secondryBg");
        }

        .countNo {
          position: absolute;
          top: 0;
          right: 0;
          background: $gradient;
          border-radius: 0px 16px 0px 16px;
          width: 3.2rem;
          height: 3.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          text-align: left;
          font-size: 1.2rem;
          line-height: 2.8rem;
          font-weight: 600;
        }

        &_tophead {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 2.4rem;

          img {
            border-radius: 50%;
            width: 6rem;
            height: 6rem;
            object-fit: cover;

            @media (max-width: 1399px) {
              width: 5rem;
              height: 5rem;
            }

            @media (max-width: 1199px) {
              width: 4.5rem;
              height: 4.5rem;
            }

            @media (max-width: 991px) {
              width: 4rem;
              height: 4rem;
            }
          }

          &_textSec {
            display: flex;
            flex-direction: column;
            margin-left: 1.6rem;

            h6 {
              font-size: 1.8rem;
              line-height: 2.8rem;
              font-weight: 600;

              @include theme() {
                color: theme-get("cardtitle222");
              }

              @media (max-width: 1199px) {
                font-size: 1.6rem;
                line-height: 2.4rem;
              }
            }

            p {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: "Inter-Regular", sans-serif;
              font-size: 1.6rem;
              line-height: 1;
              font-weight: 400;

              @include theme() {
                color: theme-get("textgray");
              }

              @media (max-width: 1199px) {
                font-size: 1.4rem;
              }
            }
          }
        }

        &_list {
          border-radius: 0.8rem;
          padding: 1rem;

          @include theme() {
            background: theme-get("SeccardfieldBg");
          }

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.6rem 0;

            label {
              font-size: 1.4rem;
              line-height: 1.2;
              font-weight: 500;
              opacity: 0.4;
              display: flex;
              align-items: center;

              @include theme() {
                color: theme-get("textclr");
              }

              svg {
                margin-left: 0.6rem;

                path {
                  @include theme() {
                    fill: theme-get("textclr");
                  }
                }
              }
            }

            p {
              font-size: 1.4rem;
              line-height: 1.2;
              font-weight: 500;

              @include theme() {
                color: theme-get("cardtitle222");
              }
            }
          }
        }

        .btnSize {
          height: 4.8rem;
        }
      }

      .cardViewBtn {
        width: 100%;
        max-width: 21rem;
        margin: 0 auto;

        .btnSize {
          height: 4.8rem;
        }
      }
    }
  }
}