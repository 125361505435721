@import "../../../assets/theme/_var.scss";

.common_btn {
    flex-shrink: 0;
    padding: 0 3.5rem;
    height: 4.2rem;
    letter-spacing: 0.02rem;
    border-radius: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 1.6rem;
    border: none;
    font-weight: 500;
    position: relative;
    z-index: 1;
    color: $white;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $gradient;
        border-radius: inherit;
        z-index: -1;
        transition: 200ms opacity ease-in-out;
    }

    &.without_bg_border {
        background: transparent;
        border: none;

        &::before {
            content: unset;
        }
    }

    &.whiteBorder {
        background: transparent;
        border: 1px solid $white;

        &::before {
            content: unset;
        }
    }

    &.grayBorder {
        border: 1px solid rgba($white, 0.2);
        box-shadow: 8px 8px 24px 0px rgba(9, 13, 20, 0.4);


        @include theme() {
            background-color: theme-get("grayBtnbg");
            color: theme-get('textclr');
        }

        &::before {
            content: unset;
        }
    }

    &.gradientBtnlight {
        border: 1px solid #cbd5e1;
        border-radius: 0.8rem;
        color: #102143;
        padding: 0.8rem 1.8rem;

        &::before {
            background: linear-gradient(92.62deg,
                    rgba(225, 230, 255, 1) 0%,
                    rgba(235, 226, 255, 1) 100%);
        }
    }

    &.danger_btn {
        &::before {
            background: $danger;
        }
    }

    &.small_btn {
        font-size: 1.4rem;
        height: 3.4rem;
        padding: 0 2rem;
    }

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}