@import "../../../assets/theme/_var.scss";

.sidebar {
  width: 100%;
  transition: 0.3s ease-in-out;

  @media (max-width: 1199px) {
    transform: translate(-100%);
    max-width: 30rem;
    position: fixed;
    height: 100vh;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: $sidebar;

    @include theme() {
      background: theme-get("sidebar");
    }

    &.active {
      transform: translateX(0);
    }
  }

  .sidebar_head {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 4rem 0 4.6rem;

    @media (max-width: 1679px) {
      padding: 1.5rem 2rem 0rem 1.5rem;
    }

    @media (max-width: 1199px) {
      display: flex;
    }

    img {
      width: 17.9rem;
      height: 4.9rem;
    }

    .close_btn {
      background-color: transparent;
      padding: 0;
      border: none;

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }

      @media (max-width: 1199px) {
        width: 3.2rem;

        height: 3.2rem;

        &::before,
        &::after {
          content: "";
          width: 3.2rem;
          height: 0.3rem;
          display: block;
          transform-origin: left;
          transform: rotate(45deg);

          @include theme() {
            background-color: theme-get("textclr");
          }
        }

        &::after {
          margin-top: 1.9rem;
          transform: rotate(-45deg);
        }

        svg {
          display: none;
        }
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1199px) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 2rem;
    }

    li {
      position: relative;

      @media (max-width: 1199px) {
        padding: 1.5rem 2rem;
      }

      a {
        // background-color: green;
        padding: 0.4rem 1.5rem;
        // height: 6rem;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 500;
        width: 100%;
        border-radius: 0.8rem;
        transition: 0.3s ease-in-out;

        @include theme() {
          color: theme-get("textclr");
        }

        &:hover,
        &.active {
          background: linear-gradient(
            92.62deg,
            rgba(143, 91, 255, 1) 0%,
            rgba(0, 87, 255, 1) 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: 0.3s ease-in-out all;
          font-weight: 700;

          &::after {
            opacity: 1;
          }

          &::before {
            width: 100%;
          }
        }
      }
      .dropdown-nav-item {
        position: relative;
        a {
          .dropdown-nav-value {
            display: flex;
            gap: 4px;
            align-items: center;
            svg {
              @include theme() {
                path {
                  stroke: theme-get("textclr");
                  fill: theme-get("textclr");
                }
              }
            }
          }
        }
      }
      .drop-down {
        position: absolute;
        z-index: 3;
        top: 20px;
        width: 160px;
        border-radius: 0px 0px 10px 10px;
        // flex-shrink: 0;
        // padding-left: 24px;
        // padding-right: 24px;
        padding-top: 10px;
        padding-bottom: 23px;
        border-radius: 0px 0px 10px 10px;
        @include theme() {
          background: theme-get("headerbg");
        }
        //background: linear-gradient(180deg, #000 0%, #141414 100%);
        p {
          padding-top: 15px;
          a {
            @include theme() {
              color: theme-get("textclr");
            }
          }
        }
      }
    }
  }
}

.sidebar_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // backdrop-filter: blur(1rem);
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  // background-color: rgb(42 40 40 / 94%) !important;
  z-index: $sidebar_overlay;
  opacity: 0;
  visibility: hidden;
  transition: 200ms ease-in-out;
  @include theme() {
    background-color: theme-get("blurEffect");
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.list-for-trade {
  .connect_btn {
    position: relative;
    margin: 0 2rem;
    width: auto;
    border-radius: 5px;
    background: linear-gradient(90deg, #0057ff 0%, #0043c6 100%);

    .app-button {
      color: #fff;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 12px 36px;
      border-radius: 5px;
      background: linear-gradient(90deg, #0057ff 0%, #0043c6 100%);
      &:hover {
        color: #ffffff !important;
      }
    }
  }
}

li:hover .list-for-trade .connect_btn .app-button {
  color: #ffffff !important;
}
.themsClass {
  max-width: 100% !important;
  overflow: hidden !important;
  .themButton button {
    background-color: transparent;
    border: none;
  }
}

@media (max-width: 575px) {
  .list-for-trade {
    width: 113px;
    .connect_btn {
      .app-button {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 10px 18px;
      }
    }
  }
}

.close-icon {
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
}

@media (max-width: 1199px) {
  .sidebar ul li:first-child {
    width: 250px;
    padding: 5px 0px 5px 0px;
    @include theme() {
      background-color: theme-get("td_bg");
    }
  }
  .sidebar ul li:nth-child(4) {
    width: 100%;
    padding: 5px 0px 5px 0px;
    margin-bottom: 2px;
    @include theme() {
      background-color: theme-get("td_bg");
    }
  }
  .sidebar ul li:nth-child(2) {
    padding: 2px 0px;
  }
  .sidebar ul li:nth-child(3) {
    padding: 0px 0px;
  }
  .sidebar ul li:nth-child(5) {
    width: 100%;
    padding: 5px 0px 5px 0px;
    margin-bottom: 2px;
    @include theme() {
      background-color: theme-get("td_bg");
    }
  }
}
@media screen and (max-width: 1199px) and (min-width: 769px) {
  .app-buttonf {
    padding: 1.3rem 2rem 1.3rem 1.5rem !important;
  }
}
@media screen and (max-width: 768px) {
  .connect_btnf a {
    padding: 5px 0px 5px 15px !important;
  }
}
.text-primary {
  color: #5a8dee !important;
}

.card {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  background-color: #fff;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #475f7b;
  overflow: hidden;
}
.rc-accordion-toggle {
  display: flex;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1.5rem;
  cursor: pointer;
  justify-content: space-between;
  transition: 0.3s;
  @include theme() {
    background-color: theme-get("td_bg");
  }
}
.rc-accordion-toggle.active {
  @include theme() {
    background-color: theme-get("swapBtnBg");
  }
  @include theme() {
    border-bottom: 1px solid theme-get("toggle-bottom-border");
  }
  transition: 0.9s ease;
}
.rc-accordion-toggle.active .rc-accordion-icon {
  transform: rotate(180deg);
}

.rc-accordion-card {
  // border: 1px solid #ddd;
  // border-radius: 5px;
  margin-bottom: 2px;
  overflow: hidden;
}
.rc-accordion-card:last-child {
  margin-bottom: 0;
}
.rc-accordion-title {
  font-weight: 500;
  font-size: 14px;
  position: relative;
  margin-bottom: 0;
  color: #475f7b;
  transition: 0.3s;
}
.rc-accordion-toggle.active .rc-accordion-title {
  @include theme() {
    color: theme-get("th_color");
  }
}
.rc-accordion-icon {
  position: relative;
  @include theme() {
    color: theme-get("th_color");
  }
  transition: 0.35s;
  font-size: 1.5rem;
}
.rc-accordion-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px;
}
.rc-accordion-body p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #727e8c;
  padding: 5px 0px 5px 0px;
}
.rc-collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  // transition: height 0.9s ease;
  transition: height 200ms ease-in-out;
}
.rc-collapse.show {
  height: auto;
  @include theme() {
    background: theme-get("td_bg");
  }
}
.rc-accordion-body .accordion-body p:hover {
  border-radius: 5px;
  @include theme() {
    background-color: theme-get("bgWhite33");
  }
}
