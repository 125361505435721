@import "../../../assets/theme/_var.scss";

.footer {
  max-width: 1430px;
  margin-left: auto;
  margin-right: auto;
  // border-top: 1px solid;
  // background-color: pink;
  // color: #fff;
  // padding: 40px 0;
  @include theme() {
    border-top: 1px solid theme-get("footerBorderClr");
  }
  @media (max-width: 575px) {
    max-width: 375px;
    // background-color: green;
  }

  .footer-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    // justify-content: space-between;
    max-width: 1040px;
    // margin: 0 auto;
    // padding: 0 20px;
    align-items: flex-start;
    gap: 80px;
    padding-top: 20px;
    display: flex;
    @media (max-width: 575px) {
      flex-direction: column;
      align-items: center;
      max-width: 375px;
    }

    .footer-section {
      flex: 1;
      max-width: 20%;
      // background-color: red;
      @media (max-width: 575px) {
        max-width: 375px;
        // background-color: pink;
      }

      h4 {
        // color: #fff;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
        @include theme() {
          color: theme-get("footer_titletxt");
        }
      }

      ul {
        list-style: none;
        padding: 0;

        @media (max-width: 575px) {
          max-width: 375px;
        }
        li {
          margin-bottom: 10px;
          font-size: 12px;
          // color: #c5c5c5;
          a {
            @include theme() {
              color: theme-get("footertxtclr");
            }
          }

          &:hover,
          &.active {
            background: linear-gradient(
              92.62deg,
              rgba(143, 91, 255, 1) 0%,
              rgba(0, 87, 255, 1) 100%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: 0.3s ease-in-out all;

            &::after {
              opacity: 1;
            }

            &::before {
              width: 100%;
            }
          }
        }
      }

      p {
        // color: #d9d9d9;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 10px;
        @media (max-width: 575px) {
          max-width: 375px;
        }
        @include theme() {
          color: theme-get("footertxtclr");
        }
      }
    }

    .logo-section {
      max-width: 25%;
      @media (max-width: 575px) {
        max-width: 375px;
      }

      img {
        width: 150px;
        margin-bottom: 20px;
      }
    }

    .social-icons {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 20px;
      max-width: 108px;
      svg {
        @include theme() {
          path {
            // stroke: theme-get("textclr");
            fill: theme-get("textclr");
          }
        }
      }

      .icon {
        width: 16px;
        height: 16px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .footer-bottom {
    p {
      text-align: center;
      padding-top: 20px;
      // border-top: 1px solid #333;
      font-size: 12px;
      // color: #777;
      @include theme() {
        color: theme-get("footertxtclr");
      }
    }
  }
}

.sm-footer {
  // background-color: #000c1d;
  color: white;
  width: 100%;
  padding: 20px;
  max-width: 100%;
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .footer-logo {
    margin-bottom: 30px;

    img {
      width: 100px;
      margin-bottom: 15px;
    }

    p {
      margin: 0 auto;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .footer-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    .footer-section {
      margin-bottom: 20px;
      text-align: left;
      width: 200px;

      h4 {
        font-size: 1.1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        span {
          font-size: 0.8em;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 10px 0 0 0;

        li {
          font-size: 0.9em;
          margin: 5px 0;
          cursor: pointer;
          transition: color 0.3s;

          &:hover {
            color: #0052ff;
          }
        }
      }
    }
    .liquidityPage {
      width: 300px;
      .accordion-header {
        width: 300px;
      }
    }
  }

  .footer-contact {
    h4 {
      margin-bottom: 10px;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 5px 0;
    }
  }
}

.accordion-header {
  width: 300px;
  .accordion-button {
    background-color: transparent !important;
  }

  svg {
    @include theme() {
      path {
        // stroke: theme-get("textclr");
        fill: theme-get("textclr");
      }
    }
  }
}
.accordion-body {
  text-align: left;
  background-color: transparent !important;
  box-shadow: none !important;
  p {
    font-family: Inter !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    // padding-bottom: 9px;
    @include theme() {
      color: theme-get("footertxtclr") !important;
    }
  }
}
// .mob-check{
//   display: flex !important;
//   justify-content: left !important;
//   flex-direction: column !important;
// }
