@import "../../../assets/theme/_var.scss";

.table_card {
    .table_card_head {
        display: flex;
        align-items: center;
        background: $gradient;
        padding: 0.8rem 2rem 0.8rem 4rem;
        border-radius: 1.6rem 1.6rem 0 0;

        @media (max-width:767px) {
            padding: 0.8rem 1.5rem;
            flex-direction: column;
            align-items: flex-start;
        }

        >h3 {
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 2.2rem;
            letter-spacing: 0em;
            text-align: left;
            color: $white;
            flex: 0 0 20%;
        }

        .table_card_filters {
            margin-left: auto;
            display: flex;
            align-items: center;

            >div {
                &:not(:first-child) {
                    margin-left: 2rem;
                }
            }

            @media (max-width:767px) {
                margin: 1rem 0 0;
                width: 100%;
            }

            @media (max-width: 499px) {
                display: block;

                >div {
                    width: 100% !important;

                    &:not(:first-child) {
                        margin: 0.8rem 0 0;
                    }
                }
            }

            .search_input {
                width: 100%;
                max-width: 35rem;
                position: relative;
                z-index: 0;

                @media (max-width:499px) {
                    max-width: 100%;
                }

                span {
                    width: 2.4rem;
                    height: 2.4rem;
                    position: absolute;
                    top: 50%;
                    left: 14px;
                    transform: translate(0%, -50%);
                    z-index: 1;

                }

                input {
                    height: 3.8rem;
                    width: 100%;
                    padding: .6rem 1.4rem .7rem 1.4rem;
                    border-radius: 0.8rem;
                    border: none;
                    background: #0000001a;
                    backdrop-filter: blur(28px);
                    color: $white !important;
                    font-size: 1.6rem;
                    padding-left: 4.3rem;
                    font-weight: 500;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        opacity: 0.6;
                        color: $white !important;

                    }

                    @include theme() {
                        color: theme-get("textclr");
                    }
                }
            }

            .common_select {
                flex-shrink: 0;

                .common_select_inner {
                    .react-select {

                        .react-select__control {
                            height: 3.8rem;
                            cursor: pointer;

                            .react-select__value-container {

                                .react-select__single-value,
                                .react-select__placeholder {
                                    text-align: start;
                                    padding: 0;
                                    margin: 0;
                                }
                            }

                            .react-select__input-container {
                                font-family: $fontSecondry;
                                font-size: 1.6rem;
                                font-weight: 500;
                                line-height: 1;

                                @include theme() {
                                    color: theme-get("bodyBg");
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}