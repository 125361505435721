@import "../../../assets/theme/_var.scss";

.ticker-slider {
  // background-color: green;
  width: 100%;
  padding: 10px, 0px;
  margin-bottom: 100px;
  display: flex;
  // flex: none;
  align-items: center;
  justify-content: center;

  overflow-x: auto;
  overflow: hidden;
  // white-space: nowrap;
}
.ticker-card {
  display: flex;
  flex: none;
  align-items: center;
  width: 280px;
  row-gap: 10px;
  // background-color: #1a1a1a;
  padding: 10px 15px;
  border-radius: 10px;
  margin-left: 12px;
  // color: #fff;
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  @include theme() {
    background-color: theme-get("marqueebg");
    color: theme-get("textclr");
  }

  &__icon {
    width: 30px;
    height: 30px;
    border-radius: 9999px;
    margin-right: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-right: auto;

    .symbol {
      font-weight:600;
      font-size: 14px;
    }

    .name {
      font-weight:500;
      font-size: 14px;
    }
  }

  &__value {
    font-weight: bold;
    font-size: 14px;
    &--negative {
      color: #e74c3c;
    }
    &--positive {
      color: #2ecc71;
    }
  }
}
