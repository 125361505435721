@import "../../../../assets/theme/_var.scss";

.withdraw_modal {
    @include theme() {
        .modal-content {
            .modal-body {
                padding: 2.4rem 1.4rem 2.4rem 2rem;
            }
        }

        .common_input {
            label {
                display: block;
                text-align: right;
                color: rgba(theme-get("textclr"), 0.6);

                span {
                    color: rgba(theme-get("textclr"), 1);
                }
            }

            .rightIcon {
                display: block;
                max-width: fit-content;
                right: 1.6rem;
            }

            .max_btn {
                font-family: $fontSecondry;
                font-size: 1.2rem;
                font-weight: 500;
                line-height: 1.8rem;
                border-radius: 5rem;
                letter-spacing: 0em;
                text-transform: uppercase;
                border: none;
                outline: none;
                color: theme-get("bodyBg");
                background-color: theme-get("textclr");
                width: 4rem;
                height: 2.2rem;
            }
        }

        .action_btn {
            display: flex;
            align-items: center;
            margin-top: 4rem;

            >button {
                flex-shrink: 1;
                padding: 0;

                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }
    }
}