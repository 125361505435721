/*pagination css */
.pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .pagination .page-item {
    font-size: 1.2rem;
    padding: 0.3rem;
  }
  
  .pagination .page-item .page-link {
    font-size: 1.2rem;
    padding: 0.75rem 1.25rem;
  }
  
  .pagination .page-item.active .page-link {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
  
  .pagination .page-link:hover {
    background-color: #0056b3;
    color: #fff;
  }
  
  .pagination .page-item:first-child .page-link,
  .pagination .page-item:last-child .page-link {
    padding: 0.75rem 1.5rem;
  }
  
  /* For samll devicess */
  /* @media (max-width: 576px) {
      .pagination .page-item:not(.prev):not(.next):not(.first):not(.last) {
        display: none;
      }
    } */
  @media (max-width: 576px) {
    .pagination .page-item {
      font-size: 0.85rem;
      padding: 0.5rem;
    }
  
    .pagination .page-item .page-link {
      font-size: 0.85rem;
      padding: 0.5rem 0.75rem;
    }
  }
  
  /* For medium devices  */
  @media (max-width: 768px) {
    .pagination .page-item {
      font-size: 1rem;
      padding: 0.6rem;
    }
  
    .pagination .page-item .page-link {
      font-size: 1rem;
      padding: 0.6rem 1rem;
    }
  }
  
  /* For large devices */
  @media (max-width: 992px) {
    .pagination .page-item {
      font-size: 1.1rem;
      padding: 0.7rem;
    }
  
    .pagination .page-item .page-link {
      font-size: 1.1rem;
      padding: 0.7rem 1.2rem;
    }
  }
  
  /* Dark Theme Styles */
  .pagination .dark .page-link {
    background-color: #343a40;
    color: #fff;
    border-radius: 5px;
  }
  
  .dotButton:hover .page-link {
    pointer-events: inherit;
    cursor: inherit;
  }
  
  .lightTheme .page-link {
    background-color: white !important; 
    color: #0056b3 !important;
    border-radius: 5px;
  }
  
  .darkTheme .page-link {
    background-color: #343a40 !important;
    color: white !important;
    border-radius: 5px;
  }
  
  .pagination .dark .page-link:hover {
    background-color: #0056b3;
    color: #fff;
  }
  /* Light mode styles */
  .pagination .light .page-link {
    border-radius: 5px;
  }