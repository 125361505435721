.flex-container {
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // padding: 20px;
  border-radius: 15px;
  // background-color: pink;
  overflow: hidden;

  @media (max-width: 575px) {
    border-radius: 0%;
  }

  .flex-item {
    flex: none;
    max-width: 300px;
    height: 170px;
    object-fit: contain;
    // background-color:green;
    border-radius: 20px;
    overflow: hidden;
    // width: 20%;
    margin: 0 10px;
    // box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    // &:hover {
    //   transform: translateY(-10px);
    //   box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
    // }

    img {
      width: 100%;
      height: 100%;
      // object-fit:cover;
    }

    .content {
      padding: 20px;
      text-align: center;
      color: white;

      h3 {
        font-size: 1.5rem;
        margin-bottom: 10px;
      }

      p {
        font-size: 1rem;
        margin-bottom: 20px;
      }

      button {
        background-color: #007bff;
        border: none;
        padding: 10px 20px;
        color: white;
        cursor: pointer;
        border-radius: 5px;
        font-size: 1rem;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}

.carousel {
  position: relative;
  .flex-item {
    flex: none;
    max-width: 300px;
    height: 170px;
    object-fit: contain;
    // background-color:green;
    border-radius: 20px;
    overflow: hidden;
    // width: 20%;
    margin: 0 10px;
    // box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    // &:hover {
    //   transform: translateY(-10px);
    //   box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
    // }

    img {
      width: 100%;
      height: 100%;
      // object-fit:cover;
    }

    .content {
      padding: 20px;
      text-align: center;
      color: white;

      h3 {
        font-size: 1.5rem;
        margin-bottom: 10px;
      }

      p {
        font-size: 1rem;
        margin-bottom: 20px;
      }

      button {
        background-color: #007bff;
        border: none;
        padding: 10px 20px;
        color: white;
        cursor: pointer;
        border-radius: 5px;
        font-size: 1rem;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}


// .custom-carousel {
//     // background-color: #000;
//     padding: 2rem 0;

//     .carousel-group {
//       max-width: 78.75rem;
//       margin-left: auto;
//       margin-right: auto;
//       display: flex;
//       justify-content: center;
//       gap: 1rem;
//       background-color: green;
//     }

//     .carousel-item-content {
//       flex: 1;
//       text-align: center;
//       max-width: 300px;
//       background-color: #fff;
//       border-radius: 1rem;
//       overflow: hidden;
//       position: relative;
//       transition: transform 0.5s ease-in-out;

//       &:nth-child(1) {
//         transform: translateX(-33.33%);
//       }

//       &:nth-child(2) {
//         transform: translateX(0%);
//       }

//       &:nth-child(3) {
//         transform: translateX(33.33%);
//       }
//     }

//     .carousel-image {
//       width: 100%;
//       height: 150px;
//       object-fit: cover;
//     }

//     .carousel-caption {
//       padding: 1rem;
//       h3 {
//         font-size: 1.25rem;
//         margin-top: 0.5rem;
//       }

//       p {
//         font-size: 0.9rem;
//         margin-bottom: 1rem;
//       }

//       .carousel-button {
//         background-color: #007bff;
//         border: none;
//         padding: 0.5rem 1rem;
//         border-radius: 0.5rem;
//         font-size: 0.9rem;
//         color: #fff;
//         cursor: pointer;
//         transition: background-color 0.3s;

//         &:hover {
//           background-color: #0056b3;
//         }
//       }
//     }
//   }
