.Main-info {
  margin: 0 1rem;
}

table.table.table-dark.table th {
  background: #142128 !important;
  color: #fff;
}
table.table.table-dark.table th {
  background: #142128 !important;
}
table.table.table-dark.table td {
  background: #142128 !important;
}

.main-graph {
  background-color: #142028;
  border-radius: 1.5rem;
  padding: 1rem;
}

.tableDark {
  background-color: red !important;
}

.bottom {
  border-radius: 1.5rem;
  padding: 1rem;
}

.icons-one {
  color: #818ea3;
}

.info-section {
  margin-top: 3rem;
  border-radius: 15px;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .info-section {
    padding: 0;
  }
}

.info-section thead tr th {
  padding: 10px 20px !important;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  /* color: white; */
}
.tablehead {
  color: #fff;
}

.theadwhite {
  color: #333;
}

.info-section tbody tr td {
  padding: 10px 20px !important;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #cdffe7;
}

.info-section tbody tr td {
  padding: 10px 20px !important;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #818ea3;
}

.info-section tbody tr td {
  padding: 10px 0px;
}

.info-section tbody .buy-row td {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #26a69a;
}

.info-section tbody .lightTd td {
  color: #26a69a;
  font-size: 14px;
}

.info-section tbody .sell-row td {
  color: #ef5350;
}

.info-section tbody tr .changeColorOne {
  color: #ef5350;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.info-section tbody tr .BuyColor {
  color: #26a69a;
}

.info-section tbody tr .changeColorTwo {
  color: #00b8d8;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.info-section tbody tr .changeColorHash a {
  color: #00b8d8;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}
.info-section tbody tr {
  border-color: #444444 !important;
}
.light {
  color: white;
}

/* Light theme table styles */
.table-light-theme {
  background-color: #f8f9fa !important;
  color: black;
}
.info-section-wrapper {
  width: 100%;
  height: 100%;
}

.table-container {
  margin: 0;
  padding: 0;
  border-radius: 15px;
  /* overflow-y: auto;
    max-height: 800px; */
}
.table-wrapper.dark-border {
  border: 1px solid #ccc;
}

.table-wrapper {
  overflow: hidden;
  border-radius: 10px;
}
.table tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.table tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.table tr:last-child td {
  border-bottom: none;
}

.number-card {
  width: 22px;
  height: 20px;
  display: flex;
  background-color: #818ea3;
  padding: 3px 6px 0px;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
  border-bottom: 4px solid transparent;
  transition: border-color 0.3s ease;
  border-radius: 5px;
}

.number-card[data-status="buy"] {
  border-bottom-color: green;
}

.number-card[data-status="sell"] {
  border-bottom-color: red;
}

.table-container {
  margin-bottom: 1rem;
}


.loading-container,
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; 
}

.loading-container {
  min-height: 20px; 
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin-top: 20px;
}

.copied-message {
  position: absolute;
  top: 200px;
  left: 0;
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: #de3333;
}
