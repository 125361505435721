.ct-toast-success {
  h4.ct-heading {
    color: #000 !important;
  }
}
.ct-toast-error {
  h4.ct-heading {
    color: #000 !important;
  }
}
#ct-container{
  //z-index: 50 !important;
//  position: relative !important;
}
.ct-toast{

  // z-index: -1 !important;
  // border: solid red !important;
}
.ct-toast-info {
  h4.ct-heading {
    color: #000 !important;
  }
}

.ct-toast {
  svg {
    width: 20px;
    height: 20px;
  }
}
