@import "~bootstrap/scss/bootstrap";

.app-container {
  padding: 0px 1rem;
  color: #ffffff;
}

.main-content {
  display: flex;
  gap: 2.5rem;
  min-height: calc(100dvh - 135px);

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    height: 800px;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.dashboard-sidebar {
  width: 300px;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    width: 300px;
  }
}

.header-title {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  padding: 0.75rem 0;
  border-radius: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: #007bff !important;
  transition: background-color 0.3s, color 0.3s;

  // &.light-button {
  //   background-color: #007bff !important;
  //   color: #ffffff !important;
  // }

  // &.dark-button {
  //   background-color: #333333 !important; 
  //   color: #fafafa !important;
  // }
}



// .main-section {
// flex: 1;
// background-color: #142028;
// border-radius: 1.5rem;
// display: flex;
// flex-direction: column;
// padding: 1rem;
// }
.main-section {
  flex: 1;
  background-color: #142028;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  &.lightSection {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #000;
  }

  .chart-container {
    margin-top: 1rem;
    background-color: #1a2a33;
    border-radius: 10px;
    padding: 1rem;

    &.lightSection .chart-container {
      background-color: #f3f3f3;
    }
  }
}


// .chart-container {
// width: 100% ; 
// height: 100%;
// display: flex;
// align-items: center;
// justify-content: center;
// margin-top: 1rem;
// }
.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  &.darkChart {
    background-color: #1a2a33;
  }

  &.lightChart {
    background-color: #f3f3f3;
    border: 1px solid #ccc;
  }
}


.searchinput {
  margin-bottom: 20px;
  padding: 1rem;
  border-radius: 10px;

  &.dark {
    background-color: #142028;
  }

  &.light {
    background-color: #f5f5f5;
  }
}

.caret-large-input {
  height: 50px;
  width: 60%;
  border-radius: 0px 5px 5px 0px;
  padding: 0 1rem;
  outline: none;
  font-size: 16px;
  cursor: pointer;

  &.dark {
    background-color: #3A4956;
    border: 2px solid #0d1310;
    @media (max-width:786px) {
      font-size: 10px;
    }

    &::placeholder {
      color: #818EA3;
    }
  }

  &.light {
    background-color: #fff;
    border: 2px solid #ccc;

    &::placeholder {
      color: #555;
    }
  }
}

.search-button {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  background-color: #0d1310;
  border: 2px solid #0d1310;
  cursor: pointer;

  &.lightButton {
    background-color: #ccc;
    border: 2px solid #aaa;
  }

  &:hover {
    background-color: #0d1310cb;
    border: 1px transparent solid;

    &.lightButton:hover {
      background-color: #aaa;
    }
  }
}


.form-search {
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.search-button {
  flex-shrink: 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caret-large-input {
  flex-grow: 1;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .search-input-wrapper {
    width: 100%;
  }

  .caret-large-input {
    width: 100%;
    font-size: 10px;
  }

  .form-search {
    margin-left: 0%;
  }
}






// .button-container {
//   display: flex;
//   margin-bottom: 10px;
//   float: right;
// }

// .theme-button {
//   background-color: transparent;
//   // color: white;
//   font-size: 12px;
//   border: 2px solid rgb(113, 111, 111);
//   border-radius: 0.375rem;
//   padding: 0.5rem 1rem;
//   cursor: pointer;
//   margin-right: 0.5rem;
//   transition: background-color 0.3s, border-color 0.3s, color 0.3s;

//   &:last-child {
//     margin-right: 0;
//   }

//   &.active {
//     background-color: #007bff;
//     border-color: #007bff;
//     color: #fff;
//   }

//   // Light theme styles
//   &.lightTheme {
//     border-color: #aca7a7;
//     color: #807d7d !important;

//     &.active {
//       background-color: #007bff;
//       border-color: #007bff;
//       color: #fff;
//     }
//   }

//   // Dark theme styles
//   &.darkTheme {
//     //border-color: #e71717;
//     color: #fa3939;

//     &.active {
//       background-color: #007bff;
//       border-color: #007bff;
//       color: #fff;
//     }
//   }
// }
.button-container {
  display: flex;
  margin-bottom: 10px;
  float: right;
}

.theme-button {
  background-color: transparent;
  font-size: 12px;
  border: 2px solid rgb(113, 111, 111);
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }

  &.lightTheme {
    border-color: #aca7a7;
    color: #807d7d;

    &.active {
      background-color: #007bff;
      border-color: #007bff;
      color: #fff;
    }
  }

  &.darkTheme {
    color: #8f8d8d;

    &.active {
      background-color: #007bff;
      border-color: #007bff;
      color: #fff;
    }
  }
}


.lighText {
  color: #000;
}

.darkText {
  color: white;
}