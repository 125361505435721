@import "~bootstrap/scss/bootstrap";

.pool-card-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 12px;
  border-radius: 1rem;
  max-width: 100%;
  margin: 0 auto;

  &.darkCol {
    background-color: #142028;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: white;
  }

  &.lightCol {
    background-color: #fff;
    color: #000;
    box-shadow: 0 10px 60px 0 #0000001a;
  }
  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 185px);
  }

  .content-wrapper {
    padding: 0 1rem;
  }

  .stats-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 1rem;

    .stat-card {
      padding: 0.5rem;
      border-radius: 0.375rem;

      &.darkStat {
        background-color: #0b1217;
      }

      &.lightStat {
        background-color: #f3f3f3;
      }

      .label {
        color: #818ea3;
        font-size: 12px;
      }

      .value {
        font-size: 12px;
        font-weight: bold;
      }

      .flex-value {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        img {
          width: 1rem;
          height: auto;
        }
      }
    }
  }

  .footer {
    text-align: center;
    width: 100%;
    margin-top: 0.5rem;
    background-color: #23323c;
    border-radius: 0 0 1rem 1rem;
    padding: 0.375rem;

    button {
      color: #cacedb;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}
